/* App.css */
.App {
  font-family: Arial, sans-serif;
  background-color: #1a1a1a;
  color: #fff;
  text-align: center;
}

.header h2 {
  font-family: "Michroma", sans-serif;
}

.services,
.why-choose-us,
.faq,
.testimonials,
.contact {
  padding: 30px 20px;
}

h1,
h2 {
  margin-bottom: 20px;
}

.service-item,
.feature,
.faq-item,
.testimonial-item {
  margin-bottom: 15px;
}

.quote-button {
  background-color: #ff914d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.michroma-regular {
  font-family: "Michroma", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.michroma-bold {
  font-family: "Michroma", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.orbitron-regular {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: italic;
}

.orbitron-thin {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: italic;
}

.dm-sans-regular {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.dm-sans-thin {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
